import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../slices";
// for future use in PTR (pull to refresh) or "refresh" button
import { isOfPolicyType } from "domain/policyType";
import {
  getPolicies,
  getDoctors,
  getClaims,
  getCompensations,
  getClaimHistory,
  getCompensationHistory,
  getVisits,
} from "../slices/insuranceSlice";
import { InsuranceState } from "../domain";
import { useAppDispatch } from "../config/store";
import DoughnutCharts from "../components/DoughnutCharts";
import BarChart from "../components/BarChart";
import styled from "styled-components";
import Colors from "../styles/Colors";
import Responsive from "../config/Responsive";

import { Link, useHistory } from "react-router-dom";
import Button from "../components/Button/Button";
import IconCard from "../components/IconCard";
import ListVertical from "../components/ListVertical";
import PersonalManager from "../components/PersonalManager";
import PolicyCategories from "../components/PolicyCategories";
import Calendar from "../components/ReactCalendar/Calendar";
import Slider from "../components/ReactSlider/Slider";
import RightSidebar from "../components/RightSidebar";
import ClaimLoss from "../icons/ClaimLoss";
import CreditCardIcon from "../icons/CreditCard";
import PocketIcon from "../icons/PocketIcon";
import VisitDoctor from "../icons/VisitDoctor";
import WarrantySentIcon from "../icons/Warranty";
import PolicyPaymentModal from "components/PolicyPaymentModal";
import Loader from "../components/Loader";
import DashboardTabs, {
  VisitsTable,
  ClaimsTable,
  CompensationsTable,
} from "../components/DashboardTabs";
import { hasPersonalManager } from "domain/types";
import PoliciesSlider from "../components/PoliciesSlider";
import Fonts from "../styles/Fonts";
/* import { dashboardContentWidth } from "../config/Responsive"; */
import PlusIcon from "../icons/PlusIcon";

const LeftSide = styled.div`
  flex: 1;
  /* max-width: $ {dashboardContentWidth("default")}; */

  ${Responsive.fullHD} {
    max-width: calc(
      100% - ${Responsive.constants.rightSidebarWidth.fullHD}px - 50px
    );
  }
  ${Responsive.desktop} {
    max-width: calc(100% - ${Responsive.constants.rightSidebarWidth.desktop}px);
  }
  ${Responsive.laptop} {
    max-width: calc(
      100% - ${Responsive.constants.rightSidebarWidth.laptop}px - 50px
    );
  }
  ${Responsive.tablet} {
    max-width: calc(
      100% - ${Responsive.constants.rightSidebarWidth.tablet}px - 50px
    );
  }
  ${Responsive.mobile} {
    width: 100%;
    /* max-width: unset; */
  }
`;
const DashboardContainer = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  ${Responsive.desktop} {
  }
  ${Responsive.laptop} {
  }
  ${Responsive.tablet} {
  }
  ${Responsive.mobile} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const DashboardCenter = styled.div`
  ${Responsive.desktop} {
    width: 100%;
  }
  ${Responsive.laptop} {
  }
  ${Responsive.tablet} {
  }
  ${Responsive.mobile} {
  }
`;

const IconCardButton = styled.button`
  width: 100%;
  outline: none;
  border: unset;
  border-radius: 10px;
  &:focus {
    outline: none;
  }
  // function add 10px from iconCard page
  ${Responsive.desktop} {
    margin-bottom: 20px;
  }
  ${Responsive.screens.NarrowNavBar} {
    /* min-width: 330px; */
  }
  ${Responsive.laptop} {
    margin-bottom: 10px;
  }
  ${Responsive.tablet} {
    margin-bottom: 10px;
  }
  ${Responsive.mobile} {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
// custom icon style
const CreditCardIconJs = styled(CreditCardIcon)`
  width: 30px;
  height: 22px;
  ${Responsive.laptop} {
    width: 20px;
    height: 15px;
  }
  ${Responsive.tablet} {
    width: 20px;
    height: 15px;
  }
`;
const PocketIconJs = styled(PocketIcon)`
  width: 26px;
  height: 29px;
  ${Responsive.laptop} {
    width: 19px;
    height: 21px;
  }
  ${Responsive.tablet} {
    width: 19px;
    height: 21px;
  }
`;
const WarrantySentIconJs = styled(WarrantySentIcon)`
  width: 26px;
  height: 29px;
  ${Responsive.laptop} {
    width: 19px;
    height: 21px;
  }
  ${Responsive.tablet} {
    width: 19px;
    height: 21px;
  }
`;
const ClaimLossIconJs = styled(ClaimLoss)`
  width: 26px;
  height: 29px;
  ${Responsive.laptop} {
    width: 19px;
    height: 21px;
  }
  ${Responsive.tablet} {
    width: 19px;
    height: 21px;
  }
`;
const VisitDoctorIconJs = styled(VisitDoctor)`
  width: 31px;
  height: 29px;
  ${Responsive.laptop} {
    width: 22px;
    height: 20px;
  }
  ${Responsive.tablet} {
    width: 22px;
    height: 20px;
  }
`;
const ChartsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding: 5px;
  box-sizing: border-box;
  overflow: hidden;
  ${Responsive.laptop} {
    margin-top: 25px;
  }
  ${Responsive.tablet} {
    margin-top: 25px;
  }
  ${Responsive.mobile} {
    margin-top: 25px;
    flex-direction: column;
  }
`;

const Block = styled.div`
  margin-bottom: 30px;
`;
const BlockTitle = styled.h1`
  color: #636381;
  font-size: 17px;
  line-height: 25px;
  font-weight: 600;
  font-family: ${Fonts.FiraGOMtavruliBold};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
    ${Responsive.tablet}{
      line-height: 18px;
    }
    ${Responsive.mobile}{
      line-height: 18px;
    }
`;
const BlockContent = styled.div`
  padding: 15px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
`;

const AddIcon = styled(PlusIcon)`
  height: 10px;
  width: 10px;
  color: #fff;
  /* margin-top: 2px; */
  /* margin-right: 10px; */
  margin-top: -2px;
  ${Responsive.laptop} {
    /* margin-top: 1.3px; */
  }
  ${Responsive.tablet} {
    /* margin-top: 1.3px; */
  }
`;
const AddButton = styled(Link)`
  height: 40px;
  border-radius: 6px;
  border: unset;
  background-color: ${Colors.Primary};
  color: #fff;
  outline: none;
  font-size: 13px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
  padding: 5px 12px 3px 12px;

  &:focus,
  &:hover {
    outline: none;
    background-color: ${Colors.HoverRed};
    color: #fff;
    text-decoration: none;
  }
  ${Responsive.laptop} {
    height: 40px;
    /* width: 35%; */
    border-radius: 4px;
    font-size: 14px;
    /* margin-left: 15px;
        margin-right: 15px; */
  }
  ${Responsive.tablet} {
    height: 32px;
    /* width: 37%; */
    border-radius: 4px;
    font-size: 12px;
    line-height: 12px;
    /* margin-left: 10px;
        margin-right: 10px; */
  }
  ${Responsive.mobile} {
    height: 38px;
    /* width: 60%; */
    border-radius: 4px;
    font-size: 12px;
    line-height: 14px;
    margin: unset;
    margin-left: auto;
    /* margin-top: 20px; */
  }
`;

const ViewAllLink = styled(Link)`
  font-size: 13px;
  color: ${Colors.Primary};
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-weight: 600;
  text-align: right;
  text-decoration: underline;
  margin-left: 20px;
  margin-right: 8px;
  min-width: 50px;
`;
const AddCommandText = styled.span`
  padding-left: 8px;
  line-height: 14px;
  ${Responsive.laptop}{
    font-size: 12px
  }
  ${Responsive.tablet}{
    display: none;
  }
  ${Responsive.mobile}{
    display: none;
  }
`;


export interface DashboardProps {}
const Dashboard: React.FC<DashboardProps> = ({}) => {
  const [isPaymentModalVisible, setIsPaymentModalVisible] = React.useState(
    false
  );

  const {
    policies,
    policiesLoading,
    activeMedicalPolicy,
    claims,
    claimsLoading,
    claimHistory,
    claimHistoryLoading,
    compensations,
    compensationsLoading,
    compensationHistory,
    compensationHistoryLoading,
    visits,
    visitsLoading,
    doctors,
    doctorsLoading,
  } = useSelector<RootState, InsuranceState>((data) => data.insurance);
  const dispatch = useAppDispatch();

  const history = useHistory();

  const medicalPolicies = policies.filter(isOfPolicyType("health"));
  const userHasMedicalPolicies =
        medicalPolicies.length > 0;


  const autoPolicies = policies.filter(isOfPolicyType("auto"));
  const userHasAutoPolicies =
        autoPolicies.length > 0;
  const policiesWithManagers = policies.filter(hasPersonalManager);
  const userHasPoliciesWithManagers = false ;//policiesWithManagers.length > 0;
  //
  const userHasAccessToVisits = userHasMedicalPolicies;
  const userHasAccessToClaims = userHasMedicalPolicies;
  const userHasAccessToCompensations =
    userHasMedicalPolicies || userHasAutoPolicies;
  //
  // copied from InsuranceCategory, now displays all policies in one slider
  const policiesOfType = policies; /* .filter(isOfPolicyType(policyTypeKeyword)) */

  const [expandVisits, setExpandVisits] = useState(false);
  const [expandCompensations, setExpandCompensations] = useState(false);
  const [expandClaims, setExpandClaims] = useState(false);

  return (
    <DashboardContainer>
      <LeftSide>
        {policiesLoading ? (
          <Loader />
        ) : (
          <DashboardCenter>
            {/* "Auto", "Health" cards */}
            {/* <PolicyCategories policies={policies} /> */}
            <PoliciesSlider policies={[...policiesOfType]} />

            {/* <Slider /> */}
            {/* <DashboardTabs /> */}

            {userHasAccessToVisits && (
              <Block>
                <BlockTitle>
                  დაგეგმილი ვიზიტები ექიმთან
                  <AddButton to="/visit/add">
                    <AddIcon /><AddCommandText>ვიზიტის ჩანიშნვა</AddCommandText>
                  </AddButton>
                  {/* to="/visit/list" */}
                  <ViewAllLink to='#' onClick={() => setExpandVisits(!expandVisits)}>{expandVisits ? 'აკეცვა' : 'ყველა'}</ViewAllLink>
                </BlockTitle>
                <BlockContent>
                  <VisitsTable
                    visits={visits}
                    doctors={doctors}
                    visitsLoading={visitsLoading}
                    doctorsLoading={doctorsLoading}
                    expanded={expandVisits}
                  />
                </BlockContent>
              </Block>
            )}

            {userHasAccessToCompensations && (
              <Block>
                <BlockTitle>
                  მოთხოვნილი ანაზღაურებები
                  <AddButton to="/compensation/add">
                    <AddIcon /><AddCommandText>ანაზღაურების მოთხოვნა</AddCommandText>
                  </AddButton>
                  {/* to="/compensation/list" */}
                  <ViewAllLink to='#' onClick={() => setExpandCompensations(!expandCompensations)}>{expandCompensations ? 'აკეცვა' : 'ყველა'}</ViewAllLink>
                </BlockTitle>
                <BlockContent>
                  <CompensationsTable
                    compensations={compensations}
                    compensationsLoading={compensationsLoading}
                    compensationHistory={compensationHistory}
                    compensationHistoryLoading={compensationHistoryLoading}
                    expanded={expandCompensations}
                  />
                </BlockContent>
              </Block>
            )}

            {userHasAccessToClaims && (
              <Block>
                <BlockTitle>
                  მოთხოვნილი საგარანტიო მიმართვები
                  <AddButton to="/claim/add">
                    <AddIcon /> <AddCommandText>საგარანტიოს მოთხოვნა</AddCommandText>
                  </AddButton>
                  {/* to="/claim/list" */}
                  <ViewAllLink to='#' onClick={() => setExpandClaims(!expandClaims)}>{expandClaims ? 'აკეცვა' : 'ყველა'}</ViewAllLink>
                </BlockTitle>
                <BlockContent>
                  <ClaimsTable
                    claims={claims}
                    claimsLoading={claimsLoading}
                    claimHistory={claimHistory}
                    claimHistoryLoading={claimHistoryLoading}
                    expanded={expandClaims}
                  />
                </BlockContent>
              </Block>
            )}

            {/* <ChartsContainer>
              <BarChart
                policies={policies}
                compensationHistory={compensationHistory}
              />
              <DoughnutCharts
                policies={policies}
                compensationHistory={compensationHistory}
              />
            </ChartsContainer> */}
          </DashboardCenter>
        )}
        {isPaymentModalVisible && (
          <PolicyPaymentModal onClose={() => setIsPaymentModalVisible(false)} />
        )}
      </LeftSide>

      <RightSidebar
        rightSide={
          <>
            <ListVertical>
              {policies.length > 0 && (
                <IconCardButton
                  key={"pay-premium"}
                  onClick={() => setIsPaymentModalVisible(true)}
                >
                  <IconCard
                    key="pay-premium"
                    icon={<CreditCardIconJs color={Colors.Primary} />}
                    title={"პრემიის გადახდა"}
                  />
                </IconCardButton>
              )}
              {/*{console.log(medicalPolicies)}*/}
          {userHasMedicalPolicies && false ? (
                <PersonalManager key="manager" policy={{
                  ...policies[0],
                  managerFirstName: medicalPolicies[0].managerFirstName,
                  managerLastName: medicalPolicies[0].managerLastName,
                  managerPhone: medicalPolicies[0].managerPhone,
                  managerEmail: medicalPolicies[0].managerEmail,
                  managerPhotoId: '',
                  managerPhotoUrl: '',
                }} />
          ) : userHasAutoPolicies && autoPolicies.filter(hasPersonalManager).length > 0 ? (
            <PersonalManager key="manager" policy={autoPolicies.filter(hasPersonalManager)[0]} />
          ) : userHasPoliciesWithManagers ? (
              <PersonalManager key="manager" policy={policiesWithManagers[0]} />
          ) : null}
          {/* {userHasPoliciesWithManagers && (
            // TODO show all managers in a carousel
                <PersonalManager key="manager" policy={policiesWithManagers[0]} />
                )} */}

              {userHasAccessToCompensations && (
                <IconCardButton
                  key="new-loss"
                  onClick={() => history.push("compensation/add")}
                >
                  <IconCard
                    icon={<PocketIconJs color={Colors.Primary} />}
                    title={"ახალი ანაზღაურება"}
                  />
                </IconCardButton>
              )}

              {userHasAccessToClaims && (
                <IconCardButton
                  key="new-guaranty"
                  onClick={() => history.push("claim/add")}
                >
                  <IconCard
                    icon={<WarrantySentIconJs color={Colors.Primary} />}
                    title={"საგარანტიოს გაგზავნა"}
                  />
                </IconCardButton>
              )}

              {/* {userHasMedicalPolicies && (
                <IconCardButton key="new-claim">
                  <IconCard
                    icon={<ClaimLossIconJs color={Colors.Primary} />}
                    title={"მიმართვის გაგზავნა"}
                  />
                </IconCardButton>
                )} */}

              {/* {userHasMedicalPolicies && (
                <IconCardButton key="visit-doctor">
                  <IconCard
                    icon={<VisitDoctorIconJs color={Colors.Primary} />}
                    title={"ექიმთან ჩაწერა"}
                  />
                </IconCardButton>
                )} */}
            </ListVertical>
            {/* <Calendar /> TO DO */}
          </>
        }
      ></RightSidebar>
    </DashboardContainer>
  );
};
export default Dashboard;
